<template>
  <b-container>
    <swiper :options="swiperOption" class="swiper-box">
      <swiper-slide
        v-for="(card, i) in cards"
        :key="i"
        class="swiper-item"
        style="height:auto;"
      >
        <b-col class="h-100">
          <div class="card rounded-0 text-center h-100">
            <img
              :src="require(`@/assets/images/massagen/${card.url}`)"
              class="card-img-top rounded-0"
              :alt="card.alt"
            />
            <div class="card-body">
              <h5 class="card-title">{{ card.headline }}</h5>
              <p class="card-text">
                {{ card.text }}
              </p>
            </div>
          </div>
        </b-col>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </b-container>
</template>

<script>
// require styles
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "massagen",
  components: {
    swiper,
    swiperSlide
  },
  props: {
    msg: String
  },
  data() {
    return {
      swiperOption: {
        // Enable lazy loading
        lazy: true,
        slidesPerView: 3,
        freeMode: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          991.98: {
            slidesPerView: 2
          },
          767.98: {
            slidesPerView: 1
          }
        }
      },
      cards: [
        {
          url: "herbal.jpg",
          headline: "Herbal Kräuter Massage",
          text:
            "Die traditionelle Thai Herbal Massage ist eine spezielle Massagetechnik mit Kräuterstempeln aus thailändischen Kräutern und Gewürzen. Mit den von Wasserdampf erhitzten Stempeln werden die Energiebahnen ders Körpers massiert. So öffnen sich die Hautporen für die Aufnahme der Kräutersäfte, welche die Durchblutung des Körpers anregen und entschlackend wirken.",
          alt: "source: istock"
        },
        {
          url: "aroma.jpg",
          headline: "Aromatherapie Massage",
          text:
            "Bei dieser Massageform können Sie zwischen Bio- und Aromaöl wählen. Die Aromamassage fördert den Abbau von Stress, Giften, Schlacken und wirkt beruhigend, krampflösend und schmerzlindernd. Das allgemeine Wohlbefinden wird dadurch gesteigert.",
          alt: "Photo by chevanon-freepik"
        },
        {
          url: "akupr.jpg",
          headline: "Akupressur-Ganzkörpermassage",
          text:
            'Die traditionelle Thailändische Massage unter der thailändischen Bezeichnung „Nuad Phaen Boran"- was soviel bedeutet wie „uralte heilsame Berührung" - ist eine Massageart die seit Jahrhunderten erfolgreich angewandt wird. Durch entsprechende Dehnungen und Streckungen sowie Bearbeitung der Akupressurpunkte und Energielinien wird ein anhaltendes körperliches Wohlbefinden erzielt.',
          alt: "source: istock"
        },
        {
          url: "fussmassage.jpg",
          headline: "Fußreflexzonen Massage",
          text:
            "Bei der Fußreflexzonenmassage wird der komplette Fuß und die Unterschenkel mit einbezogen. Es werden Fußsohlen, Fußrücken sowie die Innenseite und Außenseite der Füße massiert. Hierbei werden die beiden Füße als eine Einheit angesehen. Die Fußpaare werden als Spiegel der körperlichen Gesundheit gesehen. Die Überfunktion oder Unterfunktion der Organe wird positiv beeinflusst und ausgeglichen.",
          alt: "Photo by jcomp-freepik"
        },
        {
          url: "hotstone.jpg",
          headline: "Hot Stone-Massage",
          text:
            "Bei einer Hot-Stone-Massage werden erwärmte glatte Lavasteine verwendet. Diese Lavasteine werden auf den Körper gelegt und erzielen durch ihre ausgehende Wärme eine Entspannung bis in die tiefen Muskelschichten. Dies steigert außerdem den Stoffwechselprozess im Gewebe.",
          alt: "Photo by pressfoto-freepik"
        },
        {
          url: "kopf_gesicht.jpg",
          headline: "Gesichts-/Kopfmassage",
          text:
            "Bei der Gesichts-/Kopfmassage werden Verspannungen im Nacken und Kopfbereich gelöst und die Durchblutung kann angeregt werden. Sie wirkt tief entspannend und sorgt für ein allgemeines Wohlbefinden.",
          alt: "source: Unsplash"
        },
        {
          url: "ruecken.jpg",
          headline: "Rückenmassage",
          text:
            "Bei unserer Rückenmassage werden intensive Verspannungen im Kopf-, Nacken- und Rückenbereich gezielt und nachhaltig gelöst. Wir verwenden hierbei ein Duft- oder Kräuteröl.",
          alt: "Photo by pressfoto-freepik"
        }
      ]
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.swiper-button-next,
.swiper-button-prev {
  background-color: #06251f;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-size: 18px 18px;
}
.swiper-button-next {
  right: 5%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQwLjgyMyAyNDAuODIzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNDAuODIzIDI0MC44MjM7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGc+Cgk8cGF0aCBpZD0iQ2hldnJvbl9SaWdodF8xXyIgZD0iTTE4My4xODksMTExLjgxNkw3NC44OTIsMy41NTVjLTQuNzUyLTQuNzQtMTIuNDUxLTQuNzQtMTcuMjE1LDBjLTQuNzUyLDQuNzQtNC43NTIsMTIuNDM5LDAsMTcuMTc5ICAgbDk5LjcwNyw5OS42NzFsLTk5LjY5NSw5OS42NzFjLTQuNzUyLDQuNzQtNC43NTIsMTIuNDM5LDAsMTcuMTkxYzQuNzUyLDQuNzQsMTIuNDYzLDQuNzQsMTcuMjE1LDBsMTA4LjI5Ny0xMDguMjYxICAgQzE4Ny44ODEsMTI0LjMxNSwxODcuODgxLDExNi40OTUsMTgzLjE4OSwxMTEuODE2eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCIgc3R5bGU9ImZpbGw6I0ZGRkZGRiI+PC9wYXRoPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgo8L2c+PC9nPiA8L3N2Zz4=);
}
.swiper-button-prev {
  left: 5%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGc+Cgk8Zz4KCQk8cGF0aCBkPSJNMTY4LjgzNywyNTZMMzg4LjQxOCwzNi40MThjOC4zMzEtOC4zMzEsOC4zMzEtMjEuODM5LDAtMzAuMTdjLTguMzMxLTguMzMxLTIxLjgzOS04LjMzMS0zMC4xNywwTDEyMy41ODIsMjQwLjkxNSAgICBjLTguMzMxLDguMzMxLTguMzMxLDIxLjgzOSwwLDMwLjE3bDIzNC42NjcsMjM0LjY2N2M4LjMzMSw4LjMzMSwyMS44MzksOC4zMzEsMzAuMTcsMGM4LjMzMS04LjMzMSw4LjMzMS0yMS44MzksMC0zMC4xNyAgICBMMTY4LjgzNywyNTZ6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIiBzdHlsZT0iZmlsbDojRkZGRkZGIj48L3BhdGg+Cgk8L2c+CjwvZz48L2c+IDwvc3ZnPg==);
}
.card {
  border-color: #c0ae9a;
}
</style>
