<template>
  <swiper :options="swiperOption" class="swiper-box">
    <swiper-slide v-for="(slide, i) in slides" :key="i" class="swiper-item">
      <b-container fluid>
        <b-row>
          <b-col lg="6" class="p-0">
            <b-img
              :src="require(`@/assets/images/news/${slide.url}`)"
              fluid
              :alt="slide.alt"
              class="slide-img"
            ></b-img>
          </b-col>
          <b-col lg="6" class="p-5">
            <h3>{{ slide.headline }}<br />{{ slide.headline2 }}</h3>
            <hr />
            <p>{{ slide.text }}</p>
            <b-button
              class="btn-outline-box-gold"
              variant="secondary"
              v-if="slide.btn"
              :href="slide.btnlink"
              target="_blank"
              >{{ slide.btntext }}</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
// require styles
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "News",
  components: {
    swiper,
    swiperSlide
  },
  props: {
    msg: String
  },
  data() {
    return {
      swiperOption: {
        // Enable lazy loading
        lazy: true,
        loop: true,
        slidesPerView: 1
        /*
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
        */
      },
      slides: [
        {
          url: "news1.jpg",
          headline: "15 Jahre",
          headline2: "Na Nuad-Thai",
          text:
            "Wer eine Thaimassage einmal ausprobiert hat, weiß, dass sie sich von anderen Massage-Angeboten unterscheidet. Durch die Kombination von Massage, passiven Yoga-Stellungen, sanften Dehnungen und Akupressur soll die Thaimassage Schmerzen und Verspannungen lindern und vorbeugen und Körper und Geist in Einklang bringen. Eine Erfahrung, die viele Stammkunden teilen, so wie die Familie Baumgarten aus Detmold. Dr. Walter Baumgarten, seine Frau Paula und Tochter Dr. Katja Baumgarten. Sie brachtem dem Massagestudio Na Nuad-Thai zum 15. Geburtstag die besten Grüße aus Münster und schwärmen...",
          btn: true,
          btntext: "weiterlesen",
          btnlink: require(`@/assets/pdf/10jahre.pdf`)
        }
      ]
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
hr {
  border-color: #fff6dd;
  margin-right: 50%;
}
.swiper-box {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: #06251f;
  color: #fff6dd;
  font-weight: 300;
  line-height: 1.5;
}
.swiper-item {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

@media (min-width: 991.98px) {
  .slide-img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

@media (max-width: 991.98px) {
  .swiper-box {
    height: 100%;
  }
  .swiper-item {
    height: 100%;
  }
}
</style>
