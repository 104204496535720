<template>
  <div class="fab">
    <b-button-group vertical>
      <b-button v-b-modal.phone squared variant="primary"
        ><img
          src="~@/assets/svgs/fab/phone.svg"
          width="35"
          height="35"
          alt="Phone"
      /></b-button>
      <b-button v-b-modal.present squared variant="primary"
        ><img
          src="~@/assets/svgs/fab/present.svg"
          width="35"
          height="35"
          alt="Geschenk"
      /></b-button>
    </b-button-group>
    <b-modal
      id="phone"
      size="xl"
      centered
      title="So erreichen Sie uns"
      hide-footer
      scrollable
    >
      <b-row>
        <b-col lg="6">
          <b-img
            :src="require(`@/assets/images/fab/kontakt.jpg`)"
            fluid
            alt="Kontakt"
          ></b-img>
        </b-col>
        <b-col lg="6" class="mt-5 px-5">
          <h3>Kontakt</h3>
          <p>
            Fa. Thai Consult<br />
            Inh. Treenoot Töppe<br />
            Exterstr. 11<br />
            32756 Detmold
          </p>
          <p>
            Telefon: <a href="tel:052313033270">05231 – 30 33 270</a><br />
            www.thai-massage-detmold.de
          </p>
          <p>
            Öffnungszeiten:<br />
            Montag bis Freitag von 9:30 - 19:30 Uhr<br />
            Samstag von 9:30 - 18:00
          </p>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="present"
      size="xl"
      centered
      title="Verschenken Sie Entspannung & Wohlbefinden"
      hide-footer
      scrollable
    >
      <b-img
        :src="require(`@/assets/images/fab/gutschein.jpg`)"
        fluid
        alt="Responsive image"
      ></b-img>
      <div class="p-3">
        <p class="text-center">
          Machen Sie Ihrer Familie, Freunden oder Bekannten eine Freude – mit
          unseren Geschenkgutscheinen.<br />
          Sie bestimmen den Wert des Gutscheins und wir sind Ihnen bei der
          Auswahl gerne behilflich.
        </p>
        <p class="text-center font-weight-bold">
          Fragen Sie bei Ihrem nächsten Besuch nach unseren Geschenkgutscheinen
          – wir beraten Sie gerne.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "FAB"
};
</script>
