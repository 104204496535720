<template>
  <div class="home">
    <FAB />
    <b-navbar toggleable="lg" fixed="top">
      <b-navbar-brand
        class="d-flex align-items-center"
        data-menuanchor="welcome"
        href="#welcome"
        ><img
          :src="require(`@/assets/svgs/${logo}`)"
          class="d-inline-block align-top"
          alt="Logo"
          width="40"
          height="40"
        />Na Nuad - Thai</b-navbar-brand
      >
      <button
        :class="visible ? 'is-active' : 'collapsed'"
        :aria-expanded="visible ? 'true' : 'false'"
        aria-controls="nav-collapse"
        @click="visible = !visible"
        class="navbar-toggler hamburger hamburger--collapse"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>

      <b-collapse id="nav-collapse" v-model="visible" is-nav>
        <b-navbar-nav class="ml-auto mr-4" id="myMenu">
          <b-nav-item data-menuanchor="massagen" href="#massagen"
            >Massagen</b-nav-item
          >
          <b-nav-item data-menuanchor="team" href="#team">Team</b-nav-item>
          <b-nav-item data-menuanchor="news" href="#news"
            >Neuigkeiten</b-nav-item
          >
          <b-nav-item data-menuanchor="preise" href="#preise"
            >Preise</b-nav-item
          >
          <b-nav-item data-menuanchor="kontakt" href="#kontakt"
            >Kontakt</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <full-page ref="fullpage" :options="options" id="fullpage">
      <div class="section welcome">
        <b-container :class="{ 'helper-a': windowWidth }">
          <b-row class="align-items-center">
            <b-col lg="4" class="welcome-img d-none d-lg-block">
              <b-img
                :src="require(`@/assets/images/welcome/lady.png`)"
                alt="Lady"
                fluid
              ></b-img>
            </b-col>
            <b-col lg="8" class="welcome-txt bg-box">
              <p class="h3">Willkommen bei</p>
              <h1>Na Nuad - Thai</h1>
              <p>
                Na Nuad - Thai bietet Ihnen gesundheitsfördernde Massagen in
                einer entspannten und beruhigenden Atmosphäre an. Ein Team aus
                traditionell ausgebildeten Masseurinnen aus Thailand sorgt für
                den richtigen Fluss der Körperenergie.
                <br /><br />
                <b>Wir freuen uns auf Ihren Besuch.</b><br />
                Ihr Nuad Thai Team Detmold
                <br /><br />
              </p>
              <p>
                <strong>Öffnungszeiten:</strong><br />
                Montag bis Freitag von 9:30 - 19:30 Uhr<br />
                Samstag von 9:30 - 18:00
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="section massagen">
        <div class="helper-a">
          <b-container class="mt-4 mb-5">
            <b-row>
              <b-col class="text-center bg-box">
                <b-img
                  center
                  :src="require(`@/assets/svgs/${icons}`)"
                  width="60"
                  height="60"
                  class="mb-2"
                ></b-img>
                <h2 class="mb-2">Unsere Massagen</h2>
              </b-col>
            </b-row>
          </b-container>
          <Massagen />
          <b-container class="mt-5 mb-5">
            <b-row>
              <b-col>
                <b-button
                  block
                  variant="primary"
                  class="mr-4 mb-3 btn-outline-box"
                  @click="$bvModal.show('present')"
                  >Geschenkgutscheine</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  block
                  variant="outline-primary btn-outline"
                  class="mb-3"
                  @click="$refs.fullpage.api.moveTo(5)"
                  >Zu den Preisen</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
      <div class="section team">
        <b-container :class="{ 'helper-a': windowWidth }">
          <b-row>
            <b-col class="text-center bg-box">
              <b-img
                center
                :src="require(`@/assets/svgs/${icons}`)"
                width="60"
                height="60"
                class="mb-2"
              ></b-img>
              <h2 class="mb-2">Unser Team</h2>
            </b-col>
          </b-row>
          <Team />
          <b-row>
            <b-col class="text-center pt-4">
              <h3>
                "Dem Wohlbefinden des Körpers<br />
                folgt die FreiHeit des Geistes"
              </h3>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="section news">
        <News class="helper-a" />
      </div>
      <div class="section preise">
        <b-container>
          <b-row>
            <b-col class="text-center bg-box">
              <b-img
                center
                :src="require(`@/assets/svgs/${icons}`)"
                width="60"
                height="60"
                class="mb-2"
              ></b-img>
              <h2 class="mb-2">Preisliste</h2>
            </b-col>
          </b-row>
        </b-container>
        <Preise />
      </div>
      <div class="section kontakt">
        <footer class="container-fluid">
          <b-container class="helper-a">
            <b-row>
              <b-col lg="6" class="my-auto">
                <h3>Na Nuad-Thai</h3>
                <b-row>
                  <b-col lg="6">
                    <ul>
                      <li><a data-menuanchor="news" href="#news">News</a></li>
                      <li>
                        <a data-menuanchor="massagen" href="#massagen"
                          >Massagen</a
                        >
                      </li>
                      <li>
                        <a data-menuanchor="preise" href="#preise">Preise</a>
                      </li>
                      <li>
                        <a
                          data-menuanchor="gutscheine"
                          href="#gutscheine"
                          @click="$bvModal.show('present')"
                          >Gutscheine</a
                        >
                      </li>
                    </ul>
                  </b-col>
                  <b-col lg="6">
                    <ul>
                      <li>
                        <router-link to="/datenschutz">Datenschutz</router-link>
                      </li>
                      <li>
                        <router-link to="/impressum">Impressum</router-link>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2"
                    ><b-img
                      :src="require(`@/assets/svgs/location.svg`)"
                      width="40"
                      height="40"
                      center
                    ></b-img
                  ></b-col>
                  <b-col cols="10"
                    ><p>
                      Fa. Thai Consult - Inh. Treenoot Töppe - Exterstr. 11<br />
                      32756 Detmold -
                      <strong
                        >Telefon:
                        <a href="tel:052313033270">05231 – 30 33 270</a></strong
                      >
                    </p></b-col
                  >
                </b-row>
                <b-row>
                  <b-col cols="2"
                    ><b-img
                      :src="require(`@/assets/svgs/clock.svg`)"
                      width="40"
                      height="40"
                      center
                    ></b-img
                  ></b-col>
                  <b-col cols="10"
                    ><p>
                      <strong>Öffnungszeiten:</strong> Montag bis Freitag von
                      9:30 - 19:30 Uhr <br />Samstag von 9:30 - 18:00
                    </p></b-col
                  >
                </b-row>
              </b-col>
              <b-col lg="6">
                <l-map
                  style="height: 400px; width: 100%; z-index:0;"
                  :zoom="zoom"
                  :center="center"
                  :options="{
                    zoomControl: false,
                    scrollWheelZoom: false,
                    dragging: false
                  }"
                >
                  <l-control-zoom position="bottomright"></l-control-zoom>
                  <l-tile-layer :url="url"></l-tile-layer>
                  <l-marker :lat-lng="markerLatLng"></l-marker>
                </l-map>
              </b-col>
            </b-row>
          </b-container>
        </footer>
      </div>
    </full-page>
  </div>
</template>

<script>
import FAB from "@/components/FAB.vue";
import Massagen from "@/components/Massagen.vue";
import Team from "@/components/Team.vue";
import News from "@/components/News.vue";
import Preise from "@/components/Preise.vue";
import { LMap, LTileLayer, LControlZoom, LMarker } from "vue2-leaflet";

export default {
  name: "home",
  components: {
    FAB,
    Massagen,
    Team,
    News,
    Preise,
    LMap,
    LTileLayer,
    LControlZoom,
    LMarker
  },
  data() {
    return {
      options: {
        licenseKey: "78AF58AA-B4DC4FCE-96DF03EE-7CC0D8E7",
        menu: "#menu",
        anchors: ["welcome", "massagen", "team", "news", "preise", "kontakt"],
        sectionsColor: [
          "#fff6dd",
          "#fff6dd",
          "#fff6dd",
          "#fff6dd",
          "#fff6dd",
          "#06251f"
        ],
        scrollOverflow: true,
        normalScrollElements: ".modal-open .modal",
        responsiveWidth: 992
      },
      logo: "buddha.svg",
      icons: "buddha.svg",
      visible: false,
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 17,
      center: [51.933571, 8.8799],
      bounds: null,
      markerLatLng: [51.933571, 8.8799],
      windowWidth: window.innerWidth > 992
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.windowWidth;
    }
  }
};
</script>
