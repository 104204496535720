<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card
          v-for="(price, i) in prices"
          :key="i"
          no-body
          class="overflow-hidden rounded-0 price-card"
        >
          <b-card-body>
            <b-row no-gutters>
              <b-col lg="1" class="my-auto">
                <b-card-img
                  :src="require(`@/assets/svgs/preise/${price.url}`)"
                  class="rounded-0"
                  height="50"
                  width="50"
                ></b-card-img>
              </b-col>
              <b-col lg="3" class="price-headline d-flex">
                <b-card-title class="align-self-center">
                  {{ price.headline }}
                </b-card-title>
              </b-col>
              <b-col lg="4" class="price-text d-flex">
                <b-card-text class="align-self-center">
                  {{ price.text }}
                </b-card-text>
              </b-col>

              <template v-if="price.duration === 'a'">
                <b-col class="price d-flex">
                  <b-card-text
                    cols="4"
                    class="align-self-center text-center mx-auto"
                  >
                    <span class="price-time">{{ price.time1 }}</span
                    ><br />
                    <span class="price-number">{{ price.price1 }}</span>
                  </b-card-text>
                </b-col>
              </template>

              <template v-if="price.duration === 'b'">
                <b-col class="price d-flex">
                  <b-card-text
                    cols="2"
                    class="align-self-center text-center mx-auto"
                    ><span class="price-time">{{ price.time1 }}</span
                    ><br />
                    <span class="price-number">{{ price.price1 }}</span>
                  </b-card-text>
                </b-col>
                <b-col class="price d-flex">
                  <b-card-text
                    cols="2"
                    class="align-self-center text-center mx-auto"
                  >
                    <span class="price-time">{{ price.time2 }}</span
                    ><br />
                    <span class="price-number">{{ price.price2 }}</span>
                  </b-card-text>
                </b-col>
              </template>

              <template v-if="price.duration === 'c'">
                <b-col lg="4">
                  <b-row>
                    <b-col class="price d-flex">
                      <b-card-text
                        cols="4"
                        class="align-self-center text-center mx-auto"
                        ><span class="price-time">{{ price.time1 }}</span
                        ><br />
                        <span class="price-number">{{ price.price1 }}</span>
                      </b-card-text>
                    </b-col>
                    <b-col class="price d-flex">
                      <b-card-text
                        cols="4"
                        class="align-self-center text-center mx-auto"
                      >
                        <span class="price-time">{{ price.time2 }}</span
                        ><br />
                        <span class="price-number">{{ price.price2 }}</span>
                      </b-card-text>
                    </b-col>
                    <b-col class="price d-flex">
                      <b-card-text
                        cols="4"
                        class="align-self-center text-center mx-auto"
                      >
                        <span class="price-time">{{ price.time3 }}</span
                        ><br />
                        <span class="price-number">{{ price.price3 }}</span>
                      </b-card-text>
                    </b-col>
                  </b-row>
                </b-col>
              </template>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "preise",
  data() {
    return {
      prices: [
        {
          url: "thai-herbal-massage.svg",
          headline: "Thai Herbal Massage",
          text: "Mit Kräuterkompressen",
          duration: "b",
          time1: "90 Minuten",
          price1: "102€",
          time2: "120 Minuten",
          price2: "130€"
        },
        {
          url: "fussreflexzonen-massage.svg",
          headline: "Fußreflex-zonenmassage",
          text: "Mit Duft- oder Kräuteröl",
          duration: "b",
          time1: "45 Minuten",
          price1: "45€",
          time2: "60 Minuten",
          price2: "60"
        },
        {
          url: "gesichts-massage.svg",
          headline: "Gesichtsmassage",
          text: "Für ein allgemeines Wohlbefinden",
          duration: "a",
          time1: "30 Minuten",
          price1: "32€"
        },
        {
          url: "ruecken-massage.svg",
          headline: "Rückenmassage",
          text: "Mit Duft- oder Kräuteröl",
          duration: "b",
          time1: "30 Minuten",
          price1: "32€",
          time2: "45 Minuten",
          price2: "45€"
        },
        {
          url: "hot-stone-massage.svg",
          headline: "Hot Stone Massage",
          text: "Mit warmen Lavasteinen",
          duration: "b",
          time1: "90 Minuten",
          price1: "95€",
          time2: "120 Minuten",
          price2: "125€"
        },
        {
          url: "ruecken-massage.svg",
          headline: "Akupressur-Ganzkörpermassage",
          text: "Ganzkörpermassage ohne Öl",
          duration: "c",
          time1: "60 Minuten",
          price1: "57€",
          time2: "90 Minuten",
          price2: "80€",
          time3: "120 Minuten",
          price3: "105€"
        },
        {
          url: "thai-herbal-massage.svg",
          headline: "Aroma-Ganzkörpermassage",
          text: "Ganzkörpermassage mit Bio- oder Duftöl",
          duration: "c",
          time1: "60 Minuten",
          price1: "60€",
          time2: "90 Minuten",
          price2: "85€",
          time3: "120 Minuten",
          price3: "110€"
        }
      ],
      visible: false
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.price-card {
  border: 0px;
  border-left: 13px solid #06251f;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.18);
  &:hover {
    border-left: 13px solid #2a7757;
    -webkit-box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.38);
  }

  .price-time {
    font-weight: bold;
    color: #402e19;
  }

  .price-number {
    font-weight: bold;
    font-size: 1.6rem;
    color: #3db281;
  }

  .price-headline,
  .price-text,
  .price {
    line-height: 1.3;
    h4 {
      font-family: "Open Sans", sans-serif;
      color: #402e19;
      font-size: 1.125rem;
      margin: 0;
      padding: 0;
      font-weight: bold;
    }
    p {
      font-size: 0.938rem;
      color: #97846f;
    }
    border-right: 1px solid #dccbb8;
    padding-left: 20px;
    padding-right: 20px;
    &:last-of-type {
      border-right: none;
    }
  }
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

@media (max-width: 991.98px) {
  .card-img,
  .price-headline,
  .price-text {
    text-align: center;
    border-right: none !important;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}
</style>
